import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import cogIllustration from "../images/cog.png";
import lightningPersonIllustration from "../images/lightning-person.png";
import starBadgeIllustration from "../images/star-badge.png";
import teamMeterIllustration from "../images/team-meter.png";
import wrenchDocumentIllustration from "../images/wrench-document.png";
import janPhoto from "../images/jan-photo.jpg";
import jenPhoto from "../images/jen-photo.jpg";
import rosePhoto from "../images/rose-photo.jpg";
import rexPhoto from "../images/rex-photo.jpg";
import noorPhoto from "../images/noor-photo.jpg";
import mohamedPhoto from "../images/mohamed-photo.jpg";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[
          `TREC Dental Consulting`,
          `TREC Dental`,
          `Dental Consulting`,
          `Teamwork`,
          `Respect`,
          `Exceptional Efficiencies`,
          `Continuous & Never-ending Improvement`
        ]}
        title="Home"
      />

      <section className="text-center bg-blue-700 px-4 py-16 md:p-20">
        <h2 className="text-4xl text-white font-bold inline-block mb-4 max-w-3xl">
          TREC Dental Consulting believes in the success of every dentist.
        </h2>

        <p className="text-xl text-white inline-block max-w-3xl">
          We are committed to solving your dental challenges by integrating
          expertise and innovation into your practice.
        </p>
      </section>
      <section className="text-center p-12">
        <h2 className="text-4xl font-bold">Our TREC Values</h2>
      </section>
      <section className="md:flex" id="expertise">
        <div className="bg-blue-700 px-4 py-10 md:px-8 md:w-1/4">
          <h2 className="text-2xl font-bold text-white leading-tight mb-3">
            Teamwork
          </h2>
          <p className="text-xl text-white">
            Team is a way of being not just what you do. It's a way of thinking,
            behaving and an egoless way of working and winning together.
          </p>
        </div>
        <div className="bg-green-600 px-4 py-10 md:px-8 md:w-1/4">
          <h2 className="text-2xl font-bold text-white leading-tight mb-3">
            Respect
          </h2>
          <p className="text-xl text-white">
            We respect ourselves, our clients, our suppliers and each other.
            It's about building a culture of respect and integrity.
          </p>
        </div>
        <div className="bg-orange-500 px-4 py-10 md:px-8 md:w-1/4">
          <h2 className="text-2xl font-bold text-white leading-tight mb-3">
            Exceptional Efficiencies
          </h2>
          <p className="text-xl text-white">
            Providing high quality services, we strive to implement the most
            efficient processes.
          </p>
        </div>
        <div className="bg-red-600 px-4 py-10 md:px-8 md:w-1/4">
          <h2 className="text-2xl font-bold text-white leading-tight mb-3">
            Continuous & Never-ending Improvement
          </h2>
          <p className="text-xl text-white">
            We are endlessly fascinated with how we can improve to enhance the
            client experience.
          </p>
        </div>
      </section>
      <section className="flex flex-wrap px-4 py-16 md:p-20">
        <div className="md:w-1/2 mb-16 md:px-6">
          <img
            alt="cog wheel illustration"
            className="w-32 mb-4"
            src={cogIllustration}
          />
          <h2 className="text-2xl font-bold uppercase mb-2">
            Optimization & Profitability
          </h2>
          <h3 className="text-xl font-bold mb-1">Continuous Improvement</h3>
          <p className="text-lg">
            The practices we manage are efficient, organized and highly
            profitable. We focus on reducing expenses, increasing productivity
            and managing consistent, maximized cash flow to improve your bottom
            line. We continuously look for ways to improve, learn and increase
            efficiency.
          </p>
        </div>
        <div className="md:w-1/2 mb-16 md:px-6">
          <img
            alt="star with badge illustration"
            className="w-32 mb-4"
            src={starBadgeIllustration}
          />
          <h2 className="text-2xl font-bold uppercase mb-2">
            Better Patient Experience
          </h2>
          <h3 className="text-xl font-bold mb-1">Adding Value</h3>
          <p className="text-lg">
            Our goal is to improve the patient experience by training, educating
            and mentoring each team member so that they are adding value in
            every encounter.
          </p>
        </div>
        <div className="md:w-1/2 mb-16 md:px-6">
          <img
            alt="team members with speedometer illustration"
            className="w-32 mb-4"
            src={teamMeterIllustration}
          />
          <h2 className="text-2xl font-bold uppercase mb-2">Team</h2>
          <h3 className="text-xl font-bold mb-1">Achieving Synergy</h3>
          <p className="text-lg">
            We develop high performance, healthy teams. We use the most recent
            techniques and research-based strategies to achieve successful
            teams.
          </p>
        </div>
        <div className="md:w-1/2 mb-16 md:px-6">
          <img
            alt="lightning bold with person illustration"
            className="w-32 mb-4"
            src={lightningPersonIllustration}
          />
          <h2 className="text-2xl font-bold uppercase mb-2">HR Management</h2>
          <h3 className="text-xl font-bold mb-1">
            Let Us Take Care of Your Biggest Headache
          </h3>
          <p className="text-lg">
            Let us take care of job listings and initial screening for new
            hires. We also offer training and orientation of new team members,
            as well as the preparation of office manuals and contracts.
          </p>
        </div>
        <div className="md:w-1/2 md:px-6">
          <img
            alt="wrench with document illustration"
            className="w-32 mb-4"
            src={wrenchDocumentIllustration}
          />
          <h2 className="text-2xl font-bold uppercase mb-2">
            Capital Projects
          </h2>
          <h3 className="text-xl font-bold mb-1">Build Your Dream Clinic</h3>
          <p className="text-lg">
            We coordinate new office design and construction as well as project
            manage office renovations and expansions.
          </p>
        </div>
      </section>
      <section className="bg-green-600 text-center px-4 py-16 p-20">
        <h2 className="text-4xl font-bold text-white max-w-4xl mx-auto">
          TREC Dental Consulting brings together a group of experienced
          consultants and managers to make dentists' lives easier.
        </h2>
      </section>
      <section className="flex flex-wrap px-4 py-16 md:p-20" id="team">
        <div className="md:w-1/2 mb-16 md:px-6">
          <img alt="Dr. Jan Jaffer" className="w-40 mb-4" src={janPhoto} />
          <h2 className="text-2xl font-bold mb-2">Dr. Jan Jaffer, DDS</h2>
          <p className="text-lg">
            A 2002 graduate of the University of the Pacific in San Francisco,
            Jan is currently a partner in 12 dental practices in Calgary. He
            also has ownership interests in 2 CrossFit gyms and a dental
            laboratory. His preferred area of clinical practice is implantology
            and cosmetic dentistry. A student of management methodologies and an
            accomplished presenter, Jan brings expertise in the areas of team
            motivation, mentoring and operational management of our client
            clinics.
          </p>
        </div>
        <div className="md:w-1/2 mb-16 md:px-6">
          <img alt="Dr. Noor Jaffer" className="w-40 mb-4" src={noorPhoto} />
          <h2 className="text-2xl font-bold mb-2">
            Dr. Noor Jaffer, DDS, FAGD
          </h2>
          <p className="text-lg">
            Over his 40 year career in dentistry, Noor has built 10 new offices
            and undertaken 3 major expansions of existing clinics. Along with
            this design and construction experience he has the organizational
            skills to set up systems of management from patient flow and
            sterilization to IT issues and the integration of various software
            and hardware components. While he still covers as an in-house locum
            in several clinics, Noor’s expertise is in facility design,
            construction, on-going maintenance and training in team
            communication skills.
          </p>
        </div>
        <div className="md:w-1/2 mb-16 md:px-6">
          <img alt="Rose Jaffer" className="w-40 mb-4" src={rosePhoto} />
          <h2 className="text-2xl font-bold mb-2">Rose Jaffer</h2>
          <p className="text-lg">
            Having started her dental career over 35 years ago as chairside
            assistant, Rose has experienced first-hand the tremendous changes in
            our industry. She has been part of the evolution of the digital
            paperless office while maintaining the soft touch family feel in
            this high tech environment. She brings great insights into the art
            of effective scheduling and the financial cash flow management of
            the successful dental practice.
          </p>
        </div>
        <div className="md:w-1/2 mb-16 md:px-6">
          <img alt="Mohamed Ismail" className="w-40 mb-4" src={mohamedPhoto} />
          <h2 className="text-2xl font-bold mb-2">Mohamed Ismail, CPA, CGA</h2>
          <p className="text-lg">
            With over 8 years of experience in several industries, Mohamed
            brings extensive knowledge in processing financial data, management
            reporting and key performance indicators. He works closely with
            office managers in areas of accounts receivables and office KPIs.
          </p>
        </div>
        <div className="md:w-1/2 mb-16 md:mb-0 md:px-6">
          <img alt="Jen Reyes" className="w-40 mb-4" src={jenPhoto} />
          <h2 className="text-2xl font-bold mb-2">Jen Reyes</h2>
          <p className="text-lg">
            As a clinical assistant with front desk expertise, Jennifer has been
            part of the expansion of the TREC Clinics over the past 10 years.
            She brings strengths in training new staff as well as organizational
            skills for office set-ups and the procurement and stocking of the
            offices to maximize on quantity discounts and competitive pricing.
          </p>
        </div>
        <div className="md:w-1/2 md:px-6">
          <img alt="Rex Nova" className="w-40 mb-4" src={rexPhoto} />
          <h2 className="text-2xl font-bold mb-2">Rex Nova</h2>
          <p className="text-lg">
            With his experience in project management and staff training, Rex
            brings an eye for detail as our Operations Officer. He works with
            our teams to streamline processes and ensure our teams are
            performing at optimal level; continually identifying areas to
            improve and how to best support our people.
          </p>
        </div>
      </section>
      <section className="bg-red-600 text-center px-4 py-16 md:p-20" id="edge">
        <h2 className="text-4xl font-bold text-white max-w-4xl mx-auto">
          Get the TREC Edge
        </h2>
      </section>
      <section className="max-w-4xl mx-auto px-4 py-16 md:p-20">
        <img
          alt="cog wheel illustration"
          className="w-32 mb-4"
          src={cogIllustration}
        />
        <h2 className="text-2xl font-bold mb-4">Book Dr. Jan Jaffer</h2>
        <h3 className="text-xl font-bold mb-1">
          Profitability and Optimization - Full Day Training Program with Dr.
          Jan Jaffer
        </h3>
        <p className="text-lg mb-4">
          An in depth look at the numbers and where growth areas are in your
          office. How to increase production and decrease costs increasing
          overall profitability.
        </p>
        <p className="text-lg font-bold">Full Day: $4000 + Expenses</p>
      </section>
    </Layout>
  );
}

export default IndexPage;
